import { exists } from '../tools/exists';
import { docReady, importLoader } from '../tools/events';

docReady(function () {
    // Add your js module file imports here
    if (
        exists('[data-behaviour="image-carousel"]') ||
        exists('[data-behaviour="testimonial-slider"]') ||
        exists('[data-behaviour="hotpodhome-slider"]') ||
        exists('[data-behaviour="team-slider"]')
    ) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./slider');
            });
        });
    }

    if (exists('[data-behaviour="change-filter"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./filter');
            });
        });
    }

    if (exists('[data-behaviour="menu-toggle"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./menu');
            });
        });
    }

    if (exists('[data-behaviour="faq"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./faq');
            });
        });
    }

    if (exists('[data-behaviour="tabs"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./tabs');
            });
        });
    }

    if (exists('[data-behaviour="video-banner"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./video-banner');
            });
        });
    }

    if (exists('[data-behaviour="swap-links-logged-in"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./links');
            });
        });
    }

    if (exists('[data-behaviour="video-embed"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./video-embed');
            });
        });
    }

    if (exists('[data-behaviour="app-link"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./app-link');
            });
        });
    }

    if (exists('[data-behaviour="video"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./video');
            });
        });
    }

    if (exists('[data-behaviour="courses"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./courses');
                import('./modals');
            });
        });
    }

    if (exists('[data-behaviour="page-tabs"]')) {
        window.requestAnimationFrame(function () {
            setTimeout(function () {
                import('./page-tabs');
            });
        });
    }
});
